import { defineStore } from "pinia"
import { HttpService } from "@/helpers/http-service"
import { CONSTANT } from "@/helpers/constant"
import _ from "lodash"
import { ApiResponse } from "@/types/apiResponse"
export interface Visibility {
  id: string
  salesChannelIds: string[]
}

const httpService = new HttpService()
const httpServiceWithoutLoader = new HttpService(false)

export const useProductRangeStore = defineStore({
  id: "productRange",
  state: () => ({
    ProductRanges: {},
    salesRepProductRanges: {} as any,
    assortmentTeam: [],
    createdAssortmentTeam: {},
    isAssortmentTeamDeleted: false,
    isAssortmentTeamUpdated: false,
    assortmentDetails: {},
    assortmentProductDetails: {},
    changedProductRangeId: "",
    eecomTpAssortmentProducts: [] as any,
    visibility: [] as Visibility[],
    duplicateLoadingIndicatorId: ''
  }),
  actions: {
    async fetchSalesRepProductRangeList(bodyParams: any, customerId: string) {
      try {
        const fetchSalesRepCustomerProductRangeUrl = CONSTANT.API.SALES_REP_PRODUCT_RANGE.replace("{id}", customerId)
        const result: ApiResponse = await httpService.post(fetchSalesRepCustomerProductRangeUrl, bodyParams)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const ProductRanges = _.get(result, "data", null)
          if (ProductRanges) {
            if(!this.salesRepProductRanges) {
              return
            }
            this.salesRepProductRanges = ProductRanges
          }
        } else {
          if(!this.salesRepProductRanges) {
            return
          }
          this.salesRepProductRanges = {}
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async updateProductRangeStatus(bodyParams: any, productRangeId: string) {
      try {
        const updateProductRangeStatusUrl = CONSTANT.API.UPDATE_PRODUCT_RANGE.replace("{id}", productRangeId)
        const result: ApiResponse = await httpService.patch(updateProductRangeStatusUrl, bodyParams)
        if (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
          const ProductRanges = _.get(result, "data", null)
          if (ProductRanges) {
            if(!this.salesRepProductRanges) {
              return
            }
            this.salesRepProductRanges = ProductRanges
          }
          return true
        } else {
          this.salesRepProductRanges = {}
          return false
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async deleteProductRange(productRangeId: string) {
      try {
        const deleteProductRangeUrl = CONSTANT.API.DELETE_PRODUCT_RANGE.replace("{id}", productRangeId)
        const result: ApiResponse = await httpService.delete(deleteProductRangeUrl)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const ProductRanges = _.get(result, "data", null)
          if (ProductRanges) {
            this.salesRepProductRanges = ProductRanges
          }
        } else {
          this.salesRepProductRanges = {}
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },

    async fetchAssortmentTeam() {
      try {
        const fetchAssortmentTeamUrl = CONSTANT.API.ASSORTMENT.FETCH_ASSORTMENT_TEAM
        const result: ApiResponse = await httpService.post(fetchAssortmentTeamUrl)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const elements = _.get(result, "data.elements", null)
          if (elements) {
            this.assortmentTeam = elements
          }
        } else {
          this.assortmentTeam = []
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async deleteAssortmentTeam(assortmentProductId: string, teamId: string) {
      try {
        const deleteAssortmentTeamUrl = CONSTANT.API.ASSORTMENT.DELETE_ASSORTMENT_TEAM.replace("{id}", assortmentProductId).replace("{teamId}", teamId)
        const result: ApiResponse = await httpService.delete(deleteAssortmentTeamUrl)
        if (result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
          this.isAssortmentTeamDeleted = true
        }
      } catch (error) {
        this.isAssortmentTeamDeleted = false
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async updateAssortmentTeam(bodyParams: any) {
      try {
        const result: ApiResponse = await httpService.post(CONSTANT.API.ASSORTMENT.UPDATE_ASSORTMENT_TEAM, bodyParams)
        if (result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
          this.isAssortmentTeamUpdated = true
        }
      } catch (error) {
        this.isAssortmentTeamUpdated = false
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async createAssortmentTeam(bodyParams: any) {
      try {
        const fetchAssortmentTeamUrl = CONSTANT.API.ASSORTMENT.CREATE_ASSORTMENT_TEAM
        const result: ApiResponse = await httpService.post(fetchAssortmentTeamUrl, bodyParams)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const element = _.get(result, "data.elements[0]", null)
          if (element) {
            this.createdAssortmentTeam = element
          }
        } else {
          this.createdAssortmentTeam = {}
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async setCoverAssortmentProductMedia(assortmentProductMediaId: any) {
      try {
        const patchAssortmentProductMediaUrl = CONSTANT.API.ASSORTMENT.SET_COVER_MEDIA.replace("{id}", assortmentProductMediaId)
        const result: ApiResponse = await httpService.get(patchAssortmentProductMediaUrl)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async unSetCoverAssortmentProductMedia(id: any) {
      try {
        const patchAssortmentProductMediaUrl = CONSTANT.API.ASSORTMENT.UNSET_COVER_MEDIA.replace("{id}", id)
        const result: ApiResponse = await httpService.get(patchAssortmentProductMediaUrl)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async deleteAssortmentProductMedia(assortmentProductId: any) {
      try {
        const deleteAssortmentProductMediaUrl = CONSTANT.API.ASSORTMENT.DELETE_MEDIA.replace("{id}", assortmentProductId)
        const result: ApiResponse = await httpService.delete(deleteAssortmentProductMediaUrl)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async deleteAllAssortment(bodyParams: any) {
      try {
        const result: ApiResponse = await httpService.post(CONSTANT.API.ASSORTMENT.DELETE_ALL_ASSORTMENT, bodyParams)
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async deleteAssortmentById(assortmentProductId: any, salesChannelIds: string[]) {
      try {
        const deleteAssortmentProductByIdUrl = CONSTANT.API.ASSORTMENT.DELETE_ASSORTMENT_BY_ID.replace("{id}", assortmentProductId)
        const result: ApiResponse = await httpService.delete(deleteAssortmentProductByIdUrl, { salesChannelIds })
        return result && result.status === CONSTANT.HTTP_STATUS.NO_CONTENT
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
          return false
        }
        console.error(error)
      }
    },
    async cloneAssortmentProducts(assortmentProductId: string, bodyParams: any) {
      try {
        const cloneProductRangeUrl = CONSTANT.API.ASSORTMENT.CLONE_ASSORTMENT.replace("{entity}", CONSTANT.BODY_PARAMS.ENTITY.EECOM_TP_ASSORTMENT_PRODUCT).replace("{id}", assortmentProductId)
        return await httpService.post(cloneProductRangeUrl, bodyParams)
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
        return errors
      }
    },
    async fetchCustomerAssortmentDetailsByCustomerId(customerId: string, bodyParams: any, isDisplayLoader: boolean) {
      try {
        const fetchCustomerAssortmentDetailsByCustomerIdURL = CONSTANT.API.ASSORTMENT.ASSORTMENT_DETAILS_BY_CUSTOMER_ID.replace("{customerId}", customerId)
        let result: ApiResponse
        if (isDisplayLoader) {
          result = await httpService.post(fetchCustomerAssortmentDetailsByCustomerIdURL, bodyParams)
        } else {
          result = await httpServiceWithoutLoader.post(fetchCustomerAssortmentDetailsByCustomerIdURL, bodyParams)
        }
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          this.assortmentDetails = _.get(result, "data", {})
        } else {
          this.assortmentDetails = {}
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async fetchCustomerAssortmentProductDetailsByAssortmentId(assortmentId: string, bodyParams: any, withLoader?: boolean) {
      try {
        const fetchCustomerAssortmentProductDetailsByAssortmentIdURL = CONSTANT.API.ASSORTMENT.ASSORTMENT_PRODUCT_DETAILS_BY_ASSORTMENT_ID.replace("{assortmentId}", assortmentId)
        let result: any
        if (withLoader) {
          result = await httpService.post(fetchCustomerAssortmentProductDetailsByAssortmentIdURL, bodyParams)
        } else {
          result = await httpServiceWithoutLoader.post(fetchCustomerAssortmentProductDetailsByAssortmentIdURL, bodyParams)
        }
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          this.assortmentProductDetails = _.get(result, "data", {})
          this.eecomTpAssortmentProducts = _.get(result, "data.elements", [])
        } else {
          this.assortmentProductDetails = {}
          this.eecomTpAssortmentProducts = []
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
    async fetchAssortmentDetailsByCustomerId(customerId: string, bodyParams: any, isDisplayLoader: boolean) {
      try {
        const fetchCustomerAssortmentDetailsByCustomerIdURL = CONSTANT.API.ASSORTMENT.ASSORTMENT_DETAILS_BY_CUSTOMER_ID.replace("{customerId}", customerId)
        if (isDisplayLoader) {
          return await httpService.post(fetchCustomerAssortmentDetailsByCustomerIdURL, bodyParams)
        } else {
          return await httpServiceWithoutLoader.post(fetchCustomerAssortmentDetailsByCustomerIdURL, bodyParams)
        }
      } catch (error) {
        console.error(error)
        return error
      }
    },

    saveAssortmentProductsStore(products: any) {
      this.visibility = products.map((product: any) => {
        const salesChannelIds = product.eecomTpAssortmentProductVisibility?.map((visibility: any) => visibility.salesChannelId)
        return {
          id: product.id,
          salesChannelIds: salesChannelIds,
        }
      })
      this.eecomTpAssortmentProducts = products
    },

    updateAssortmentProductsStore(product: any, value?: any, key?: any) {
      const index = this.eecomTpAssortmentProducts.findIndex((item: any) => item.id === product?.id)
      if (index !== -1) {
        this.eecomTpAssortmentProducts[index] = {
          ...product,
          [key]: value,
        }
      }
    },

    updateCustomizationPriceStore(price: any, productId: string, customizationId: string, type: string) {
      const updatePrice = (customizations: any[]) => {
        const customization = customizations.find((mc: any) => mc.id === customizationId)
        if (customization) {
          customization.price = price
          if (customization.calculatedPrice) {
            customization.calculatedPrice.unitPrice = price[0].gross
          } else {
            customization.calculatedPrice = { unitPrice: price[0].gross }
          }
        }
      }

      const index = this.eecomTpAssortmentProducts.findIndex((item: any) => item.id === productId)
      if (index !== -1) {
        const product = this.eecomTpAssortmentProducts[index]

        if (type === "mandatory") {
          updatePrice(product.eecomTpMandatoryCustomizations)
        } else if (type === "optional") {
          updatePrice(product.eecomTpOptionalCustomizations)
        }
      }
    },

    async fetchPicoConfigurationUrl(id: string, assortmentProductId: string) {
      try {
        const result: any = await httpService.post(CONSTANT.API.ASSORTMENT.FETCH_ASSORTMENT_PICO_CONFIGURATION_URL.replace("{id}", id).replace("{productId}", assortmentProductId), {})
        if (result && result.status === CONSTANT.HTTP_STATUS.OK && result.data !== "undefined" && result.data.url && result.data.url !== "undefined") {
          return result.data.url
        } else {
          return null
        }
      } catch (error) {
        console.error(error)
        return error
      }
    },

    async updateProductRange(productRangeId: string) {
      try {
        const products = this.eecomTpAssortmentProducts?.map((product: any) => {
          const data: any = {
            id: product.id,
            active: product.active,
            price: product.price,
            configuration: product.configuration,
            picoConfigurationId: product.picoConfigurationId,
            eecomTpMandatoryCustomizations: product?.eecomTpMandatoryCustomizations?.map((eecomTpMandatoryCustomization: any) => ({
              id: eecomTpMandatoryCustomization.id,
              eecomTpCustomizationId: eecomTpMandatoryCustomization.eecomTpCustomizationId,
              price: eecomTpMandatoryCustomization.price,
            })),
            eecomTpOptionalCustomizations: product?.eecomTpOptionalCustomizations?.map((eecomTpOptionalCustomization: any) => ({
              id: eecomTpOptionalCustomization.id,
              eecomTpCustomizationId: eecomTpOptionalCustomization.eecomTpCustomizationId,
              price: eecomTpOptionalCustomization.price,
            })),
          }
          if (product.mediaIds !== null || product.mediaIds.length !== 0) {
            data.mediaIds = product.mediaIds
          }
          if (product.name !== null && product.name !== undefined) {
            data.name = product.name
          }
          return data
        })
        const payload = {
          id: productRangeId,
          eecomTpAssortmentProducts: products,
          visibility: this.visibility,
        }

        const updateProductRangeStatusUrl = CONSTANT.API.UPDATE_PRODUCT_RANGE.replace("{id}", productRangeId)
        const result: ApiResponse = await httpService.patch(updateProductRangeStatusUrl, payload)
        if (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
          const productRanges = _.get(result, "data", null)
          if (productRanges) {
            this.salesRepProductRanges = productRanges
          }
          return true
        } else {
          this.salesRepProductRanges = {}
          return false
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },

    updateCustomizationStore(product: any, mandatoryCustomizations: any, optionalCustomizations: any) {
      const index = this.eecomTpAssortmentProducts.findIndex((item: any) => item.id === product?.id)
      if (index !== -1) {
        this.eecomTpAssortmentProducts[index] = {
          ...product,
          configuration: false,
          eecomTpMandatoryCustomizations: mandatoryCustomizations,
          eecomTpOptionalCustomizations: optionalCustomizations,
          eecomTpAssortmentProductMedia: [],
          mediaIds: product.eecomTpAssortmentProductMedia?.map((media: any) => media.id),
        }
      }
    },

    updateConfigurationFinishedStore(product: any, configrationStatus: boolean) {
      const index = this.eecomTpAssortmentProducts.findIndex((item: any) => item.id === product?.id)
      if (index !== -1) {
        this.eecomTpAssortmentProducts[index].configuration = configrationStatus
      }
    },

    generateDuplicatedName(originalName: string, suffix: string) {
      const regluarName = `${originalName} - ${suffix}`;
      return regluarName
      // if(!this.salesRepProductRanges || this.salesRepProductRanges?.elements.length === 0) {
      //   return regluarName
      // }
      // const count = this.salesRepProductRanges.elements.filter((item: any) => item.name === regluarName).length;

      // if (count > 0) {
      //     return `${regluarName} (${count + 1})`; // +1 to indicate the next duplicate
      // } else {
      //     return regluarName;
      // }
    },

    getNextPosition() {
      // Find the maximum position value in the array
      const latestPosition = Math.max(...this.salesRepProductRanges?.elements?.map((item: any) => item.position), 0);
      // Return the next position (incremented by 1)
      return latestPosition + 1;
    },

    async duplicateProductRange(productRange: any, suffix: string) {
      if(!this.salesRepProductRanges) {
        return
      }

      const duplicatedId = `duplicated-${productRange.id}`
      const duplicatedName = this.generateDuplicatedName(productRange.translated.name, suffix)
      const duplicatedProduct = {
        ...productRange,
        name: duplicatedName,
        translated: {
          name: duplicatedName
        },
        id: `duplicated-${productRange.id}`,
        createdAt: new Date().toString(),
      }

      this.salesRepProductRanges?.elements.unshift(duplicatedProduct)
      this.duplicateLoadingIndicatorId = duplicatedId

      try {
        const bodyParams = {
          overwrites: {
            name: duplicatedName,
            position: this.getNextPosition(),
          }
        }

        const cloneProductRangeUrl = CONSTANT.API.CLONE_PRODUCT_RANGE.replace("{entity}", "eecom_tp_assortment").replace("{id}", productRange.id)
        const result: ApiResponse = await httpServiceWithoutLoader.post(cloneProductRangeUrl, bodyParams)

        this.duplicateLoadingIndicatorId = ''

        return result && result.status === CONSTANT.HTTP_STATUS.OK
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },

    async fetchAllSalesRepProductRangeList(customerId: string) {
      try {
        const fetchSalesRepCustomerProductRangeUrl = CONSTANT.API.SALES_REP_PRODUCT_RANGE.replace("{id}", customerId)
        const result: ApiResponse = await httpServiceWithoutLoader.post(fetchSalesRepCustomerProductRangeUrl)
        if (result && result.status === CONSTANT.HTTP_STATUS.OK) {
          const data = _.get(result, "data", null)
          if (data) {
            return data
          }
        } else {
          return []
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },

    async updateProductRangesByCustomerId(customerId: string, payload: any) {
      try {
        const url = CONSTANT.API.UPDATE_PRODUCT_RANGE_BY_CUSTOMER.replace("{customerId}", customerId)
        const result: ApiResponse = await httpService.patch(url, payload)
        if (result.status === CONSTANT.HTTP_STATUS.OK || result.status === CONSTANT.HTTP_STATUS.NO_CONTENT) {
          const productRanges = _.get(result, "data", null)
          if (productRanges) {
            this.salesRepProductRanges = productRanges
          }
          return true
        } else {
          this.salesRepProductRanges = {}
          return false
        }
      } catch (error) {
        const errors: any = _.get(error, `response.data.errors`, [])
        if (errors && errors.length > 0) {
          console.error(errors[0].detail)
        }
        console.error(error)
      }
    },
  },
})
